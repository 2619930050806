import AppLayout from '@hellodarwin/core/lib/components/common/layout/app-layout';
import useDebounceFunction from '@hellodarwin/core/lib/features/hooks/use-debounce-function';
import { useAppData } from '@hellodarwin/core/lib/features/providers/app-data-provider';
import useLocale from '@hellodarwin/core/lib/features/providers/locale-provider';
import { useLogin } from '@hellodarwin/core/lib/features/providers/login-provider';
import useUTM from '@hellodarwin/core/lib/features/providers/utm-provider';
import AppRoutesRender from '@hellodarwin/core/lib/features/router/app-routes-render';
import { SegmentAnalytics } from '@segment/analytics.js-core';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from './app/index';
import ProviderActionModal from './components/provider/provider-action-modal';
import RfpActionModal from './components/rfp/rfp-action-modal';
import {
  me,
  selectProfile,
  selectProfileLoading,
} from './features/api/slices/profile-slice';
import { useClientApi } from './features/api/use-client-api';
import BottomNavigation from './layout/bottom-navigation';
import Navigation from './layout/navigation';
import PageHeader from './layout/page-header';

import './App.scss';
import useAppPages from './pages';

declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS;
    _hsq: any;
  }
}

const ClientApp = () => {
  const dispatch = useAppDispatch();
  const api = useClientApi();
  const utmSource = useUTM();

  const { accessToken } = useLogin();
  const { pathname, hash, key } = useLocation();
  const { changeLanguage } = useLocale();

  const profile = useAppSelector(selectProfile);
  const isHelloLoading = useAppSelector((state) =>
    selectProfileLoading(state, 'hello'),
  );

  const appData = useAppData();
  const isSimpleDesign = useMemo(() => pathname.includes('status'), [pathname]);
  const isLoggedIn = useMemo(() => !!profile.contact_id, [profile]);

  const isAppLoading = useMemo(
    () => isHelloLoading || !isLoggedIn,
    [isHelloLoading, isLoggedIn],
  );

  //HASH
  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '' && window !== undefined) {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]);

  useEffect(() => {
    if (profile?.contact_email) {
      try {
        var _hsq = (window._hsq = window._hsq || []);
        _hsq?.push([
          'identify',
          {
            email: profile.contact_email,
          },
        ]);
      } catch (e: any) {
        console.error(e);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile?.contact_email]);

  useEffect(() => {
    const { setHeader, setNav, setBottomNav } = appData;
    if (!isSimpleDesign) {
      setHeader(<PageHeader />);
      setNav(<Navigation />);
      setBottomNav(<BottomNavigation />);
    } else {
      setHeader(<PageHeader />);
      setNav(<Navigation />);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, isSimpleDesign]);

  //--- PROFILE

  const fetchClient = useDebounceFunction(() => {
    dispatch(me({ api, utm: utmSource }));
  }, 300);

  useEffect(() => {
    if (accessToken === '') return;
    fetchClient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  useEffect(() => {
    if (!!profile.contact_preferred_language && profile.contact_id) {
      changeLanguage(profile.contact_preferred_language);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  const pages = useAppPages();

  return (
    <>
      <AppLayout isLoading={isAppLoading}>
        <AppRoutesRender pages={pages} defaultHomepage={'/dashboard'} />
      </AppLayout>
      <RfpActionModal />
      <ProviderActionModal />
    </>
  );
};

export default ClientApp;
