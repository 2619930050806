import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import { IconNode } from '@hellodarwin/icons/dist/features/entities/general';
import { ReactNode } from 'react';

export interface PriorityTileInfoProps {
  Icon: IconNode;
  value: ReactNode;
}

const PriorityTileInfo: React.FC<PriorityTileInfoProps> = ({ Icon, value }) => {
  return (
    <Div flex="row" gap={4} align="center">
      <Icon size={20} />
      <Typography elementTheme="body2">{value}</Typography>
    </Div>
  );
};

export default PriorityTileInfo;
