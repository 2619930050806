import PageLayout from '@hellodarwin/core/lib/components/common/layout/page-layout';
import useLocale from '@hellodarwin/core/lib/features/providers/locale-provider';
import { useTranslation } from '@hellodarwin/core/lib/plugins/i18n';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/index';
import BusinessPrioritySingle from '../../../components/projects/business-priority-single';
import { BusinessPrioritySingleSections } from '../../../components/projects/business-priority-single/business-priority-single-section/sections';
import {
  fetchSingleProject,
  selectProjectById,
} from '../../../features/api/slices/projects-slice';
import { useNewClientApi } from '../../../features/api/use-client-api';

const ClientSinglePriorityPage = () => {
  const { t } = useTranslation();
  const { priorityId } = useParams<{
    priorityId: string;
  }>();
  const { selectedLocale } = useLocale();
  const api = useNewClientApi();
  const dispatch = useAppDispatch();

  const priority = useAppSelector((state) =>
    selectProjectById(state, priorityId ?? ''),
  );

  useEffect(() => {
    dispatch(
      fetchSingleProject({
        api,
        project_id: priorityId ?? '',
        locale: selectedLocale,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priorityId]);

  return (
    <PageLayout
      app="client"
      goBackTo={'/strategic-planning'}
      title={priority?.title ?? ''}
      breadcrumbs={[
        {
          breadcrumbName: t('navigation.home'),
          path: '/',
        },
        {
          breadcrumbName: t('project|strategicPlanning'),
          path: '/strategic-planning',
        },
        {
          breadcrumbName: priority?.title,
        },
      ]}
    >
      {!!priority && (
        <BusinessPrioritySingle
          priority={priority}
          sections={[BusinessPrioritySingleSections.Projects]}
        />
      )}
    </PageLayout>
  );
};

export default ClientSinglePriorityPage;
