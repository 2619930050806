import Budget from '@hellodarwin/icons/dist/icons/Budget';
import Grants from '@hellodarwin/icons/dist/icons/Grants';
import Industry from '@hellodarwin/icons/dist/icons/Industry';
import Invoices from '@hellodarwin/icons/dist/icons/Invoices';
import NewWindow from '@hellodarwin/icons/dist/icons/NewWindow';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { ProjectProgramWithGrant } from '../../../features/entities';
import { dollarFormat } from '../../../features/helpers';
import { getProgramGrantStatusColor } from '../../../features/helpers/get-status-color';
import {
  attributesToProps,
  domToReact,
  HTMLReactParserOptions,
} from '../../../features/helpers/parse';
import useLocale from '../../../features/providers/locale-provider';
import { useTranslations } from '../../../features/providers/translations-provider';
import HdTag from '../../common/hd-tag';
import TagsList from '../../common/hd-tag/tags-list';
import Typography from '../../common/typography';
import FundingAmount from './funding-amout';
import ProjectProgramInfo from './project-program-info';
import {
  GrantLogo,
  ProjectProgramCardBody,
  ProjectProgramCardBodyContent,
  ProjectProgramCardBodyContentColumn,
  ProjectProgramCardContainer,
  ProjectProgramCardHeader,
  ProjectProgramCardHeaderInfos,
} from './styles';
export interface ProjectProgramCardProps {
  program: ProjectProgramWithGrant;
  programPath: string;
}
export const parseOptions: HTMLReactParserOptions = {
  replace: (domNode: any) => {
    if (domNode.attribs && domNode.name === 'a') {
      const props = attributesToProps(domNode.attribs);

      return (
        <a {...props} rel="nofollow" target="_blank">
          {domToReact(domNode.children)}
        </a>
      );
    }

    if (domNode.attribs && domNode.name === 'img') {
      return <></>;
    }
  },
};

const ProjectProgramCard: React.FC<ProjectProgramCardProps> = ({
  program,
  programPath,
}) => {
  const {
    program_name,
    program_grant_status,

    grant_logo,
    grant_display_title,
    province,
    country,
    program_id,
    funding_min_amount,
    funding_max_amount,
    percentage_funding,
    grant_description_short,
    grant_industry_sectors,
    grant_financing_type,
  } = program;
  const theme = useTheme();
  const { t } = useTranslations();
  const { selectedLocale } = useLocale();
  const applicationAmountLabelAndValue = useMemo(() => {
    const label = !!program.program_accepted_amount
      ? t('application|application_accepted_amount')
      : !!program.program_application_amount
        ? t('application|application_applied_amount')
        : !!program.program_targeted_amount
          ? t('application|application_targeted_amount')
          : undefined;
    const value = dollarFormat(
      program.program_accepted_amount ??
        program.program_application_amount ??
        program.program_targeted_amount,
      selectedLocale,
      true,
    );
    return { value, label };
  }, [program, selectedLocale]);

  return (
    <Link to={`${programPath}/${program_id}`} target="_blank">
      <ProjectProgramCardContainer>
        <ProjectProgramCardHeader>
          <GrantLogo
            src={grant_logo}
            alt={`${grant_display_title}${province ? ` ${province}` : ''}${
              country ? ` ${country}` : ''
            }`}
          >
            {grant_display_title?.[0]}
          </GrantLogo>

          <ProjectProgramCardHeaderInfos>
            <HdTag
              color={getProgramGrantStatusColor(program_grant_status, theme)}
              text={t(`programs_labels|${program_grant_status}`)}
            />
            <Typography.Title level={3} elementTheme="h6">
              {program_name}
            </Typography.Title>
            {!!grant_description_short && (
              <Typography elementTheme="body2" color={theme.colors.grey_2}>
                {grant_description_short}
              </Typography>
            )}
          </ProjectProgramCardHeaderInfos>
          <NewWindow
            size={20}
            style={{ position: 'absolute', top: 16, right: 16 }}
            color={theme.colors.purple_2}
          />
        </ProjectProgramCardHeader>
        <ProjectProgramCardBody>
          <ProjectProgramCardBodyContent>
            <ProjectProgramCardBodyContentColumn>
              <ProjectProgramInfo
                label={t('grant_single|eligibleFinancing')}
                Icon={Budget}
                value={
                  <FundingAmount
                    funding_max_amount={funding_max_amount}
                    funding_min_amount={funding_min_amount}
                    percentage_funding={percentage_funding}
                  />
                }
              />
              <ProjectProgramInfo
                label={applicationAmountLabelAndValue.label}
                Icon={Grants}
                value={
                  <ul>
                    <li
                      style={{ color: theme.colors.purple_2, fontWeight: 500 }}
                    >
                      {applicationAmountLabelAndValue.value}
                    </li>
                  </ul>
                }
              />
            </ProjectProgramCardBodyContentColumn>{' '}
            <ProjectProgramCardBodyContentColumn>
              <ProjectProgramInfo
                label={t('grant_card|eligibleIndustries')}
                Icon={Industry}
                value={
                  <ul style={{ width: '100%' }}>
                    {!!grant_industry_sectors?.length ? (
                      <>
                        {React.Children.toArray(
                          grant_industry_sectors
                            .slice(0, 4)
                            .map((tag) => <li key={tag}>{tag}</li>),
                        )}
                      </>
                    ) : (
                      <li style={{ width: '100%' }}>
                        {t('grant_card|allIndustries')}
                      </li>
                    )}
                  </ul>
                }
              />
              <ProjectProgramInfo
                label={t('grant_card|financingType')}
                Icon={Invoices}
                value={
                  <TagsList
                    mainColor={theme.colors.purple_3}
                    limit={3}
                    tags={grant_financing_type?.map((text) => ({
                      text,
                    }))}
                  />
                }
              />
            </ProjectProgramCardBodyContentColumn>
          </ProjectProgramCardBodyContent>
        </ProjectProgramCardBody>
      </ProjectProgramCardContainer>
    </Link>
  );
};

export default ProjectProgramCard;
