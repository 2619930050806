import { IconNode } from '@hellodarwin/icons/dist/features/entities/general';
import { ReactNode } from 'react';
import { useTheme } from 'styled-components';
import Div from '../../common/div';
import Typography from '../../common/typography';

export interface ProjectProgramInfoProps {
  label: string;
  value: ReactNode;
  Icon: IconNode;
}

const ProjectProgramInfo: React.FC<ProjectProgramInfoProps> = ({
  label,
  value,
  Icon,
}) => {
  const theme = useTheme();
  return (
    <Div flex="column" gap={6} style={{ paddingInline: 24, paddingBlock: 16 }}>
      <Div flex="row" gap={8}>
        <Icon size={14} color={theme.colors.grey_2} />
        <Typography elementTheme="overline" color={theme.colors.grey_2}>
          {label}
        </Typography>
      </Div>
      <Typography elementTheme="body3">{value}</Typography>
    </Div>
  );
};

export default ProjectProgramInfo;
