import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import formatRange from '@hellodarwin/core/lib/components/projects/utils/format-range';
import dollarFormat from '@hellodarwin/core/lib/features/helpers/dollar-format';
import { getStringDate } from '@hellodarwin/core/lib/features/helpers/get-formatted-date';
import parse from '@hellodarwin/core/lib/features/helpers/parse';
import useLocale from '@hellodarwin/core/lib/features/providers/locale-provider';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import { useTheme } from '@hellodarwin/core/lib/plugins/styled';
import Grants from '@hellodarwin/icons/dist/icons/Grants';
import Timeline from '@hellodarwin/icons/dist/icons/Timeline';
import { Link } from 'react-router-dom';
import { ProjectSingleProps } from '..';
import ProjectSingleHeaderInfo from './project-single-header-info';
import { ProjectSingleHeaderContainer } from './styles';
interface ProjectSingleHeaderProps extends ProjectSingleProps {}
const ProjectSingleHeader: React.FC<ProjectSingleHeaderProps> = ({
  project,
  priority,
}) => {
  const { t } = useTranslations();
  const theme = useTheme();
  const { selectedLocale } = useLocale();
  return (
    <ProjectSingleHeaderContainer>
      <Div flex="column" gap={8}>
        {!!priority && (
          <Typography color={theme.colors.grey_2} elementTheme="subtitle2">
            {t('project|project_parent')} "
            <Link to={`/strategic-planning/${priority?.project_id}`}>
              {priority?.title}
            </Link>
            "
          </Typography>
        )}
        <Typography.Title level={1} elementTheme="h5">
          {project.title}
        </Typography.Title>
      </Div>
      {!!project.description && (
        <Typography elementTheme="body1">
          {parse(project.description)}
        </Typography>
      )}
      <Div flex="row" gap={32}>
        <ProjectSingleHeaderInfo
          label={t('project|project_estimated_budget')}
          Icon={Grants}
          highlighted
        >
          {dollarFormat(project.estimated_budget, selectedLocale, true)}
        </ProjectSingleHeaderInfo>
        <ProjectSingleHeaderInfo
          label={t('project|project_timeline')}
          Icon={Timeline}
        >
          {formatRange({
            project,
            locale: selectedLocale,
            separator: selectedLocale === 'en' ? ' to ' : ' au ',
            formatValue: getStringDate,
            keys: ['started_date', 'end_date'],
          })}
        </ProjectSingleHeaderInfo>
      </Div>
    </ProjectSingleHeaderContainer>
  );
};
export default ProjectSingleHeader;
