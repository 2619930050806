import Div from '@hellodarwin/core/lib/components/common/div';
import ProjectProgramCard from '@hellodarwin/core/lib/components/projects/project-program-card';
import { ProjectProgramWithGrant } from '@hellodarwin/core/lib/features/entities';
import useLocale from '@hellodarwin/core/lib/features/providers/locale-provider';
import { useEffect } from 'react';
import { ProjectsSectionBlockProps } from '.';
import { useAppDispatch, useAppSelector } from '../../../../../app/index';
import {
  fetchProjectPrograms,
  selectProgramsByProjectId,
} from '../../../../../features/api/slices/projects-slice';
import { useNewClientApi } from '../../../../../features/api/use-client-api';
const ApplicationsSection: React.FC<ProjectsSectionBlockProps> = ({
  project,
}) => {
  const { selectedLocale } = useLocale();
  const programs: ProjectProgramWithGrant[] = useAppSelector((state) =>
    selectProgramsByProjectId(state, project?.project_id ?? ''),
  );

  const api = useNewClientApi();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      fetchProjectPrograms({
        api,
        projectId: project.project_id,
        locale: selectedLocale,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  return (
    <Div flex="column" gap={24}>
      {programs.map((program) => (
        <ProjectProgramCard program={program} programPath="/programs" />
      ))}
    </Div>
  );
};
export default ApplicationsSection;
