import AdminCard from '@hellodarwin/core/lib/components/admin/admin-card';
import Div from '@hellodarwin/core/lib/components/common/div';
import { AdminProjectResponse } from '@hellodarwin/core/lib/features/entities/projects-entities';
import { useTranslation } from '@hellodarwin/core/lib/plugins/i18n';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/index';
import {
  fetchAdmin,
  selectAdminById,
} from '../../../features/api/slices/global-slice';
import { selectProfile } from '../../../features/api/slices/profile-slice';
import { useClientApi } from '../../../features/api/use-client-api';
import BusinessPriorityHeader from './business-priority-single-header';
import BusinessPrioritySection from './business-priority-single-section';
import useBusinessPriorityBlocks, {
  BusinessPrioritySingleSections,
} from './business-priority-single-section/sections';

export interface BusinessPrioritySingleProps {
  priority: AdminProjectResponse;
  sections: BusinessPrioritySingleSections[];
}

const BusinessPrioritySingle: React.FC<BusinessPrioritySingleProps> = (
  props,
) => {
  const api = useClientApi();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { priority, sections } = props;

  const admin = useAppSelector((state) =>
    selectAdminById(state, priority?.admin_id ?? ''),
  );
  const profile = useAppSelector(selectProfile);

  const blocks = useBusinessPriorityBlocks({ priority });

  useEffect(() => {
    if (!!priority?.admin_id) {
      dispatch(fetchAdmin({ api, adminId: priority.admin_id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priority?.admin_id]);

  return (
    <Div flex="column" gap={16}>
      <BusinessPriorityHeader {...props} />
      {sections
        .filter((s) => !!blocks[s])
        .map((s) => (
          <BusinessPrioritySection {...blocks[s]} />
        ))}
      {!!admin && (
        <AdminCard
          admin={admin}
          profile={profile}
          title={t('project|adminCard.title')}
          subtitle={t('project|adminCard.subtitle')}
          fullWidth
        />
      )}
    </Div>
  );
};

export default BusinessPrioritySingle;
