import dollarFormat from '../../../features/helpers/dollar-format';
import useLocale from '../../../features/providers/locale-provider';
import { useTranslations } from '../../../features/providers/translations-provider';

export interface FundingAmountProps {
  funding_min_amount?: number;
  funding_max_amount?: number;
  percentage_funding?: number;
}

const FundingAmount: React.FC<FundingAmountProps> = ({
  funding_max_amount,
  funding_min_amount,
  percentage_funding,
}) => {
  const { t } = useTranslations();
  const { selectedLocale } = useLocale();
  return (
    <ul>
      {' '}
      {!!funding_min_amount && !!funding_max_amount ? (
        <li>
          {t('grant_single|fromMinToMax', {
            min: `${dollarFormat(funding_min_amount, selectedLocale)}`,
            max: `${dollarFormat(funding_max_amount, selectedLocale)}`,
          })}
        </li>
      ) : !!funding_min_amount ? (
        <li>
          {t('grant_single|minCount', {
            count: `${dollarFormat(funding_min_amount, selectedLocale)}`,
          })}
        </li>
      ) : (
        !!funding_max_amount && (
          <li>
            {t('grant_single|maxCount', {
              count: `${dollarFormat(funding_max_amount, selectedLocale)}`,
            })}
          </li>
        )
      )}
      {!!percentage_funding && (
        <li>
          {t('grant_single|projectCostPercent', {
            percent: `${percentage_funding}`,
          })}
        </li>
      )}
      {!funding_min_amount && !funding_max_amount && !percentage_funding && (
        <li>{t('grant_single|noCondition')}</li>
      )}
    </ul>
  );
};

export default FundingAmount;
