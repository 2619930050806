import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import { useTranslation } from '@hellodarwin/core/lib/plugins/i18n';
import Projects from '@hellodarwin/icons/dist/icons/Projects';
import Timeline from '@hellodarwin/icons/dist/icons/Timeline';
import { Dispatch, SetStateAction } from 'react';
import { SubmitRfpFlowSteps } from '.';
import RfpActionCard from './rfp-action-card';

interface SubmitRfpOptionsProps {
  openMeeting: () => void;
  setStep: Dispatch<SetStateAction<SubmitRfpFlowSteps>>;
}

const SubmitRfpOptions = ({ setStep, openMeeting }: SubmitRfpOptionsProps) => {
  const { t } = useTranslation();
  return (
    <Div flex="column" justify={'center'} align={'center'} gap={32}>
      <Typography.Title level={4}>
        {t('rfp|rfpHeader.headerTitle')}
      </Typography.Title>
      <Div
        flex={'row'}
        tablet={{ flex: 'column', align: 'center' }}
        gap={24}
        justify={'center'}
        align={'stretch'}
      >
        <RfpActionCard
          onClick={openMeeting}
          title={t('rfp|rfpActionCard.bookAMeeting.title')}
          text={t('rfp|rfpActionCard.bookAMeeting.text')}
          Icon={Timeline}
        />
        <RfpActionCard
          onClick={() => setStep(SubmitRfpFlowSteps.form)}
          title={t('rfp|rfpActionCard.submitAnRfp.title')}
          text={t('rfp|rfpActionCard.submitAnRfp.text')}
          Icon={Projects}
        />
      </Div>
    </Div>
  );
};

export default SubmitRfpOptions;
