import NavigationSider from '@hellodarwin/core/lib/components/layout/navigation';
import { NavigationLink } from '@hellodarwin/core/lib/features/entities';
import useMediaQuery from '@hellodarwin/core/lib/features/helpers/use-media-query';
import { useTranslation } from '@hellodarwin/core/lib/plugins/i18n';
import { useTheme } from '@hellodarwin/core/lib/plugins/styled';
import Accueil from '@hellodarwin/icons/dist/icons/Accueil';
import Grants from '@hellodarwin/icons/dist/icons/Grants';
import HdAcademy from '@hellodarwin/icons/dist/icons/HdAcademy';
import Help from '@hellodarwin/icons/dist/icons/Help';
import ProjectIcon from '@hellodarwin/icons/dist/icons/ProjectIcon';
import RfpIcon from '@hellodarwin/icons/dist/icons/RfpIcon';
import User from '@hellodarwin/icons/dist/icons/User';
import { useMemo } from 'react';
import { useAppSelector } from '../app/index';
import { selectProfile } from '../features/api/slices/profile-slice';
import { useClientApi } from '../features/api/use-client-api';
import showErrorNotification from '../features/utils/show-error-notifications';

const Navigation = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.tablet });
  const api = useClientApi();
  const profile = useAppSelector(selectProfile);

  const links = useMemo(() => {
    let links: NavigationLink[] = [];

    if (!isTablet) {
      links = [
        ...links,
        {
          pathname: '/dashboard',
          label: t('navigation.dashboard'),
          Icon: Accueil,
        },
      ];
    }
    links = [
      ...links,
      // {
      //   pathname: "/projects",
      //   label: t("navigation.projects"),
      //   Icon: ProjectIcon,
      // },
      {
        pathname: '/strategic-planning',
        label: t('navigation.strategicPlanning'),
        Icon: ProjectIcon,
      },
      {
        pathname: '/rfp',
        label: t('navigation.rfp'),
        Icon: RfpIcon,
      },
      {
        pathname: '/grants',
        label: t('navigation.grants'),
        Icon: Grants,
        subLinks: [
          {
            pathname: '/funding-explorer',
            label: t('navigation.fundingExplorer'),
          },
          {
            pathname: '/programs/roadmap',
            label: t('navigation.ongoingPrograms'),
          },
        ],
      },
      {
        pathname: '/resources',
        label: t('resource|resources'),
        Icon: HdAcademy,
      },

      {
        pathname: '/faq',
        label: t('navigation.faqMenu'),
        Icon: Help,
      },
    ];
    return links;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTablet, t]);

  const openBillingSession = () => {
    (async () => {
      try {
        const session = await api.fetchBillingSession(
          window?.location?.href || '',
        );
        window.location.href = session.url;
      } catch (err: any) {
        showErrorNotification(err.response.data.error_code);
      }
    })();
  };

  const userLinks: NavigationLink[] = useMemo(() => {
    const links: NavigationLink[] = [
      {
        //Le label est bon mais manque la bonne redirection et l'icone
        label: t('navigation.preferences'),
        pathname: '/profile',
        Icon: User,
      },
      {
        //Le label est bon mais manque la bonne redirection et l'icone
        label: t('navigation.billing'),
        pathname: '/billing',
        onClick: openBillingSession,

        Icon: Grants,
      },
    ];

    return links;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <NavigationSider
      links={links}
      app="client"
      userLinks={userLinks}
      profile={profile}
      defaultOpen
    />
  );
};

export default Navigation;
