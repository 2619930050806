import Button from '@hellodarwin/core/lib/components/common/button';
import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import Loading from '@hellodarwin/core/lib/components/loading';
import ContactAdvisorModal from '@hellodarwin/core/lib/components/programs/single/contact-advisor-modal';
import { useTranslation } from '@hellodarwin/core/lib/plugins/i18n';
import { useTheme } from '@hellodarwin/core/lib/plugins/styled';
import Empty from 'antd/es/empty';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/index';
import {
  fetchAllProjectsPreview,
  selectAllProjectsPreview,
  selectProjectsIsLoading,
} from '../../../features/api/slices/projects-slice';
import { useNewClientApi } from '../../../features/api/use-client-api';
import PriorityTile from '../priority-tile';

export interface PrioritiesTableProps {}

const PrioritiesTable: React.FC<PrioritiesTableProps> = () => {
  const api = useNewClientApi();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const projects = useAppSelector(selectAllProjectsPreview);
  const isLoading = useAppSelector((state) =>
    selectProjectsIsLoading(state, 'allProjects'),
  );
  const [contactAdvisorModalOpen, setContactAdvisorModalOpen] = useState(false);
  const handlecontactAdvisorModal = () =>
    setContactAdvisorModalOpen(!contactAdvisorModalOpen);

  useEffect(() => {
    dispatch(fetchAllProjectsPreview({ api }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Div flex="column" gap={16}>
      {isLoading ? (
        <Loading />
      ) : !!projects.length ? (
        projects.map((p) => <PriorityTile priority={p} />)
      ) : (
        <Div flex="column" gap={8} align="center" style={{ paddingBlock: 54 }}>
          <Empty
            description={
              <Typography elementTheme="subtitle2" color={theme.colors.grey_1}>
                {t('rfp|noRfp.all')}
              </Typography>
            }
          />
          <Typography
            elementTheme="body2"
            color={theme.colors.grey_2}
            style={{ maxWidth: 540 }}
            textAlign="center"
          >
            {t('rfp|noRfp.contactManager')}
          </Typography>
          <Button
            defaultStyle={theme.colors.yellow_1}
            tablet={{ fullWidth: true }}
            onClick={handlecontactAdvisorModal}
            style={{ marginTop: 12 }}
            size="medium"
          >
            {t('propulsion|empty.cta')}
          </Button>
          <ContactAdvisorModal
            open={contactAdvisorModalOpen}
            handleCancel={handlecontactAdvisorModal}
          />
        </Div>
      )}
    </Div>
  );
};
export default PrioritiesTable;
