import Button from '@hellodarwin/core/lib/components/common/button';
import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import { PreviewProjectResponse } from '@hellodarwin/core/lib/features/entities';
import { dollarFormat } from '@hellodarwin/core/lib/features/helpers';
import useLocale from '@hellodarwin/core/lib/features/providers/locale-provider';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import { useTheme } from '@hellodarwin/core/lib/plugins/styled';
import Invoices from '@hellodarwin/icons/dist/icons/Invoices';
import ProjectIcon from '@hellodarwin/icons/dist/icons/ProjectIcon';
import { Link, useLocation } from 'react-router-dom';
import PriorityTileInfo from './priority-tile-info';
import { PriorityTileContainer } from './styles';

export interface PriorityTileProps {
  priority: PreviewProjectResponse;
}

const PriorityTile: React.FC<PriorityTileProps> = ({ priority }) => {
  const theme = useTheme();
  const { selectedLocale } = useLocale();
  const { pathname } = useLocation();
  const { t } = useTranslations();
  return (
    <Link to={`${pathname}/${priority.project_id}`}>
      <PriorityTileContainer>
        <Div flex="column" gap={4}>
          <Typography elementTheme="subtitle2" nowrap ellipsis>
            {priority.title}
          </Typography>
          <Typography elementTheme="body3" color={theme.colors.purple_2} medium>
            {t('business_priority|budgetTotal', {
              count: dollarFormat(priority.total_budget, selectedLocale, true),
            })}
          </Typography>
        </Div>
        <Div flex="column" gap={8} style={{ width: 300 }}>
          <PriorityTileInfo
            Icon={ProjectIcon}
            value={t('business_priority|projectsTotal', {
              count: priority.total_projects ?? 0,
            })}
          />
          <PriorityTileInfo
            Icon={Invoices}
            value={t('business_priority|applicationsTotal', {
              count: priority.total_programs ?? 0,
            })}
          />
        </Div>
        <Button defaultStyle={theme.colors.purple_2} withArrowRight>
          {t('business_priority|viewCurrentState')}
        </Button>
      </PriorityTileContainer>
    </Link>
  );
};

export default PriorityTile;
